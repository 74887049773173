
function Review({ data1 }) {
    let data = data1

    const convertHtmlToText = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const checkAnswer = (index, ansindex) => {
        const quesindex = index.index
        const anindex = ansindex.ansindex
        if (data.qus[quesindex].ans[anindex].correct_fl == 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="18" height="18">
                <circle cx="10" cy="10" r="9" fill="#72E128" />
                <path d="M6,10 L9,13 L14,7" fill="none" stroke="#FFFFFF" stroke-width="2.5" strokeLinecap="round" />
            </svg>
        }
        else if (data.qus[quesindex].ans[anindex].select_fl == 1 && data.qus[quesindex].ans[anindex].correct_fl == 0) {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="19" height="19">
                <circle cx="10" cy="10" r="8" fill="#FF4D49" />
                <path d="M7,7 L13,13 M13,7 L7,13" fill="none" stroke="#FFFFFF" stroke-width="2.5" strokeLinecap="round" />
            </svg>
        }
        else {

        }
    }

    const renderFormFields = () => {
        return data.qus.map((obj, index) => {
            return <div key={index}>

                <div className="d-flex flex-column" >
                    <label htmlFor={obj.qus_id} className='form-label'><strong>{obj.qus_id} - {convertHtmlToText(obj.qus)}</strong></label>
                    <div className='col-md-8'>
                        {obj.qus_type === 'TEXT' && (
                            <input type="text" className="form-control" id={obj.qus_id} value={obj.qus[index].user_ans_text} />
                        )}

                        {obj.qus_type === 'RADIO' && (
                            <>
                                {obj.ans.map((option, ansindex) => (
                                    <label key={option.ans_id} className='form-label d-flex gap-2 text-left'>
                                        {option.seq}.
                                        <div className="d-flex gap-md-3 gap-2 align-items-center">
                                            <div>{option.ans}</div>
                                            <div>{checkAnswer({ index }, { ansindex })}</div>
                                        </div>
                                    </label>
                                ))
                                }
                            </>
                        )}

                        {obj.qus_type === 'CHECKBOX' && (
                            <>
                                {obj.ans.map((option, ansindex) => (
                                    <label key={option.ans_id} className='form-label d-flex gap-2 text-left'>
                                        {option.seq}.
                                        <div className="d-flex gap-md-3 gap-2 align-items-center">
                                            <div>{option.ans}</div>
                                            <div>{checkAnswer({ index }, { ansindex })}</div>
                                        </div>
                                    </label>
                                ))
                                }
                            </>
                        )}

                        {obj.qus_type === 'NUMBER' && (
                            <input type={obj.ans_type} className="form-control" id={obj.qus_id} />
                        )}

                        {obj.qus_type === 'FILE' && (
                            <div className='d-flex flex-column flex-md-row gap-md-3 gap-2'>
                                <input type={obj.ans_type} accept="image/*" className="form-control" id={obj.qus_id} />
                                <div >
                                    <button className='btn btn-secondary py-1'>Upload</button>
                                </div>
                            </div>
                        )}

                        {obj.qus_type === 'SELECT' && (
                            <>
                                <div key={index}>
                                    <select id={obj.qus_id} className="form-select text-secondary col-3">
                                        <option value="" disabled selected>Select</option>
                                        {obj.ans.map((option, ansindex) => (
                                            <option key={ansindex} >
                                                {option.ans}
                                            </option>))}
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        })
    }

    return (
        <>
            <div className=" d-flex flex-column justify-content-center align-items-center p-md-4 ps-md-3 p-3 pt-md-3 pt-2 mt-md-5 mt-3" >
                <h5 >Result Review</h5>
                <div className="card-body d-flex flex-column gap-2 p-md-5 p-4 pb-3">
                    {data.qus == undefined ? null : renderFormFields()}
                </div>
            </div>
        </>
    );
}

export default Review;