import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CryptoJS from 'crypto-js';


const QuizResultChart = ({ encryptedData }) => {
  let encryptedData1 = encryptedData || {};

  console.log(encryptedData1);

    var key = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
    var iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");
  const [decryptedData1, setdecryptedData1] = useState({})

  useEffect(() => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData1, key, { iv: iv });
      const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
      console.log(decryptedString);
      const decrypted = JSON.parse(decryptedString);

     
      setdecryptedData1(decrypted)
    } catch (error) {
      //console.log( error);
    }
  }, [encryptedData1]);


  const getColor = () => {
    return decryptedData1.result === 'pass' ? '#72E128' : '#FF4D49';
  };
  const getPass = () => {
    return <span style={{ color: "#72E128" }}>PASS</span>
  };
  const getFail = () => {
    return <span style={{ color: "#FF4D49" }}>FAIL</span>
  };

  return (


    <div className="card-body text-center d-flex flex-column p-5 align-items-center">

<h5 className='mb-3  rounded bg-body shadow-sm p-2 mt-0'>RESULT : {decryptedData1.result == "pass" ? getPass() : getFail()}</h5>

      <div className='d-flex flex-column gap-3 gap-md-4 col-md-6 col-11 justify-content-center align-items-center my-4'>
        <CircularProgressbar value={decryptedData1.percentage} text={`${decryptedData1.percentage}%`} strokeWidth={7}
          styles={{ path: { stroke: getColor() }, text: { fill: '#858886', fontSize: '9px' }, trail: { stroke: '#F0F0F0' }, }} />
      </div>

      <div className='col-md-12'>

      <div className='row me-0'>
          <div className='card my-md-5 my-3 bg-light col-md-5'>
            <div className='card-body bg-light rounded fw-semibold text-black-50 d-flex flex-column align-items-center '>
              <div className='card-title border-bottom col-12 mb-1 pb-2'>Total Questions: {decryptedData1["total"]}</div>
              <div className='d-flex'>
                <div>Correct Answer(s):</div>
                <div className='text-center '>&nbsp;{decryptedData1.score}</div>
              </div>
            </div>
          </div>
          <div className='col-md-2'>
            </div>
          <div className='card my-md-5 my-3 bg-light col-md-5'>
            <div className='card-body bg-light rounded fw-semibold text-black-50 d-flex flex-column align-items-center '>
              <div className='card-title border-bottom col-12 mb-1 pb-2'>Passing Score: {decryptedData1.passMarks}%</div>
              <div className='d-flex'>
                <div>Your Score:</div>
                <div className='text-center '>&nbsp;{decryptedData1.percentage}%</div>
              </div>
            </div>
          </div>
        </div>



      </div>


     
    </div>

  );
};

export default QuizResultChart;