import { useState, useEffect } from 'react';
import bgimage from '../Images/bgimage.png';
import logo from '../Images/logo.png';
import logo2 from '../Images/logo2.png';
import axios from 'axios';


var backgroundimage = {
  backgroundImage: `url(${bgimage})`,
  backgroundRepeat: "no-repeat",
  height: "100vh",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",

}
function Loginpage() {
  //const [data, setdata]=useState(0);


  useEffect(() => {

    axios.post('https://localhost:7263/api/Login')
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        return err;
      })
  }, []);

  return (
    <>


      <div className="authentication-inner row m-0 h-100">
        <div className="d-none d-md-flex flex-md-column col-lg-7 col-xl-8 align-items-center justify-content-center p-0 bg-light" style={backgroundimage}>
          <img src={logo} className='w-25' alt="logo" />
          <p className='text-primary fw-bold'>Enable Substainable Enterprises</p>

        </div>

        <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center position-relative py-sm-5 px-4 py-4 bg-white h-100vh text-secondary">

          <div className="w-px-400 mx-auto pt-5 pt-lg-0 ">
            <div className='d-flex align-items-center text-primary fw-bold d-md-none'>
              <img src={logo2} className='w-25 pb-3' alt="logo" />
              <p>Enable Sustainable Enterprises</p>
            </div>
            <h5 className="my-2">Welcome to M2i! 👋</h5>
            <p className="mb-4">Please sign-in to your account</p>

            <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" action="#" method="GET" novalidate="novalidate">
              <div className="form-floating form-floating-outline mb-3 fv-plugins-icon-container">
                <input type="text" className="form-control" id="username" name="username" placeholder="Enter your email or username" autofocus="" />
                <label for="email">Email or Username</label>
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
              <div className="mb-3 fv-plugins-icon-container">
                <div className="form-password-toggle">
                  <div className="input-group input-group-merge">
                    <div className="form-floating form-floating-outline">
                      <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                      <label for="password">Password</label>
                    </div>
                    <span className="input-group-text cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="gray" d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.79 11.79 0 0 1-4 5.19l-1.42-1.43A9.862 9.862 0 0 0 20.82 12A9.821 9.821 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.821 9.821 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13" /></svg></span>
                  </div>
                </div>
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
              <div className="mb-3 d-flex justify-content-between">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="remember-me" />
                  <label className="form-check-label" for="remember-me">
                    Remember Me
                  </label>
                </div>
                <a href="#" className="float-end mb-1">
                  <span>Forgot Password?</span>
                </a>
              </div>
              <div className="mb-3">
                <button className="btn btn-primary d-grid w-100 waves-effect waves-light" type="submit">Sign in</button>
              </div>
              <input type="hidden" id='hiddenfield' /></form>

            <p className="text-center">
              <span>New on our platform? </span>
              <a href="#">
                <span>Create an account</span>
              </a>
            </p>

            <div className="divider my-4">
              <div className="divider-text">or</div>
            </div>

            <div className="d-flex justify-content-center gap-2">
              <a href="javascript:;" className="btn btn-icon btn-lg rounded-pill btn-text-facebook waves-effect waves-light p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="royalblue" className="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </a>

              <a href="javascript:;" className="btn btn-icon btn-lg rounded-pill btn-text-twitter waves-effect waves-light p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="skyblue" className="bi bi-twitter" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                </svg>
              </a>

              <a href="javascript:;" className="btn btn-icon btn-lg rounded-pill btn-text-github waves-effect waves-light p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                </svg>
              </a>

              <a href="javascript:;" className="btn btn-icon btn-lg rounded-pill btn-text-google-plus waves-effect waves-light p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-google" viewBox="0 0 16 16">
                  <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>




    </>
  );
}

export default Loginpage;