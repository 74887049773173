import toastr, { success } from 'toastr';
import { useEffect, useState } from 'react';
import Footer from './Footer';
import logo from '../Images/logo2.png';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../index.css';
import QuizResultChart from './QuizResultChart';
import CryptoJS from 'crypto-js';
import Review from './Review';


function Questions() {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [topic, setTopic] = useState(0)
    const [text, setText] = useState("");
    const [isValidName, setIsValidName] = useState(true);
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [phone, setPhone] = useState("");
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [name, setName] = useState("");
    const [radio, setRadio] = useState("");
    const [check, setCheck] = useState([]);
    const [select, setSelect] = useState("");
    const [image, setImage] = useState(null);
    const [number, setNumber] = useState("");
    const [negnumber, setNegNumber] = useState("");
    const [posnumber, setPosNumber] = useState("");
    let unselectedQuestions = [];
    const [resultData, setResultData] = useState({});
    const [encryptedData, setencryptedData] = useState("");
    var key = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
    var iv = CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");
    const [currentPage, setCurrentPage] = useState(1);
    const questionsPerPage = 10;
    const [selectedAnswers, setSelectedAnswers] = useState({});
    let err1 = "", err2 = "", err3 = "", err4 = "", err5 = "", err6 = "", err7 = "", err8 = "", err9 = "", err10 = "", err11 = "", err12 = "", err13 = "", err14 = "", err15 = "", finalerror = "", finalerror2 = "";


    const getData = async () => {
        const usersName = JSON.stringify({ 'action': 'qusList', 'topic_id': id });
        const customConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        await axios.post('https://form.m2i.co.in/cpanel/wsApp/quiz.php', usersName, customConfig)
            .then((res) => {

                setData(res.data.data.qus)
                setData2(res.data.data)


            }).
            catch(err => console.log(err))
    };

    const isNameValid = (inputName) => {
        const nameRegex = /^[a-zA-Z\s]+$/;
        return nameRegex.test(inputName);
    }
    const handleName = (e) => {
        let name = e.target.value
        if (name !== "") {
            data2.user_name = name;
            setName(name)
            document.getElementById("Name").style.border = "2px solid rgba(99,101,120,.0759803922)"
            setIsValidName(isNameValid(name));
        }
        else {
            setName("")
        }
    }

    const validatePhone = (inputPhone) => {
        const phoneRegex = /^\d{10}$/;

        return phoneRegex.test(inputPhone);
    }
    const handlephone = (e) => {
        let phone = e.target.value
        if (phone !== "") {
            setPhone(phone)
            data2.phone = phone;
            document.getElementById("Phone").style.border = "2px solid rgba(99,101,120,.0759803922)"
            setIsValidPhone(validatePhone(phone));
        }
        else {
            setPhone("")
        }
    }

    const validateEmail = (inputEmail) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return emailRegex.test(inputEmail);
    }
    const handleemail = (e) => {
        let email = e.target.value
        if (email !== "") {
            setEmail(email)
            data2.email = email;
            document.getElementById("email").style.border = "2px solid rgba(99,101,120,.0759803922)"
            setIsValidEmail(validateEmail(email));
        }
        else {
            setEmail("")
        }
    }

    const handleimage = (index) => (e) => {
        setImage(e.target.files[0])
        //data[index].user_image = e.target.files[0]
    }
    const handleUpload = () => {
        if (!image) {
            //document.getElementById(`${data[index].qus_id}`).style.border = "2px solid red";
            err7 = "image"
            console.log(err7)
            return;
        }
        err7 = ""
        // document.getElementById("Q5").style.border = "2px solid rgba(99,101,120,.0759803922)"
        const formData = new FormData();
        formData.append('uploadfile', image);

        fetch('https://form.m2i.co.in/cpanel/uploadFile.php', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Upload successful:', data);

            })
            .catch(error => {
                //console.log(error);
            });
    };


    const handleText = (index) => (e) => {
        let text = e.target.value
        const textindex = index.index

        if (text !== "") {
            setText(text)
            data[textindex].user_ans_text = text;
        }
        else {
            setText("")
        }
        //console.log("user_ans_text = "+data.qus[textindex].user_ans_text)
    }
    const handleTextchange = (obj, index) => {
        if (text == "") {
            err8 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }
        else {
            err8 = ""
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid rgba(99,101,120,.0759803922)";
        }
    }

    const handleRadio = (index, ansindex) => (e) => {
        const value = e.target.value
        const check = e.target.checked

        const anindex = ansindex.ansindex

        if (check) {
            setSelectedAnswers(prevState => ({
                ...prevState,
                [index]: value
            }));
            const updatedData = [...data];
            const questionIndex = updatedData.findIndex(question => question.qus_id === index);

            if (questionIndex !== -1) {
                updatedData[questionIndex].ans.forEach((option, i) => {
                    option.select_fl = i === anindex ? 1 : 0;
                });
                setData(updatedData);
            }
        } else {
            setRadio("");
        }

        if (data && data.length > 0) {
            const questionToUpdate = data.find(question => question.qus_id === index);

            if (questionToUpdate) {
                for (var i = 0; i < questionToUpdate.ans.length; i++) {
                    questionToUpdate.ans[i].select_fl = i === anindex ? 1 : 0;
                }
            }
        }

    }


    const handlecheck = (index, ansindex) => (e) => {
        const value = e.target.value
        const checked = e.target.checked
        const anindex = ansindex.ansindex


        if (checked) {
            setCheck([...check, value])
            data[index - 1].ans[anindex].select_fl = 0;
        }
        else {
            setCheck(check.filter((e) => (e !== value)))

        }

        if (data[index - 1].ans[anindex].select_fl == 0) {
            data[index - 1].ans[anindex].select_fl = 1;
        }
        else {
            data[index - 1].ans[anindex].select_fl = 0;
        }

        // console.log(data.qus[2].ans[0].select_fl, data.qus[2].ans[1].select_fl, data.qus[2].ans[2].select_fl, data.qus[2].ans[3].select_fl)
    }
    const handlecheckchange = (obj, index) => {
        if (check == "") {
            err9 = `Q${data[index].qus_no}`
        }
        else {
            err9 = ""
        }
    }

    const handleselect = (index) => (e) => {
        const value = e.target.value

        if (value !== "") {
            setSelect(value)
        }
        else {
            setSelect("")
        }
        for (var i = 0; i < data[index - 1].ans.length; i++) {
            if (value == data[index - 1].ans[i].seq) {
                data[index - 1].ans[i].select_fl = 1;
            }
            else {
                data[index - 1].ans[i].select_fl = 0;
            }
        }
        //console.log(data.qus[3].ans[0].select_fl, data.qus[3].ans[1].select_fl, data.qus[3].ans[2].select_fl, data.qus[3].ans[3].select_fl)
    }
    const handleselectchange = (obj, index) => {
        if (select == "") {
            err10 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }
        else {
            err10 = ""
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid rgba(99,101,120,.0759803922)";
        }

    }

    const handlenumber = (e) => {
        let value = e.target.value

        if (value !== "") {
            setNumber(value)

        }
        else {
            setNumber("")
        }
    }
    const handlenumberchange = (obj, index) => {
        if (number == '') {
            err11 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }

        else {
            err11 = ""
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid rgba(99,101,120,.0759803922)";
        }

    }

    const handlenegnumber = (e) => {
        let value = e.target.value
        if (value !== "") {
            setNegNumber(value)
        }
        else {
            setNegNumber("")
        }
    }
    const handlenegnumberchange = (obj, index) => {
        const n = negnumber
        if (n == '') {
            err12 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }
        else if (n % 1 != 0) {
            err12 = ""
            err14 = "Q7 "
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }

        else {
            err12 = ""
            err14 = ""
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid rgba(99,101,120,.0759803922)";
        }

    }

    const handleposnumber = (e) => {
        let value = e.target.value
        if (value !== "") {
            setPosNumber(value)
        }
        else {
            setPosNumber("")
        }
    }
    const handleposnumberchange = (obj, index) => {
        const n = posnumber

        if (n == '') {
            err13 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }
        else if (n < 0) {
            err13 = ""
            err15 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }
        else if (n % 1 != 0) {
            err13 = ""
            err15 = `Q${data[index].qus_no}`
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid red";
        }

        else {
            err13 = ""
            document.getElementById(`Q${data[index].qus_no}`).style.border = "2px solid rgba(99,101,120,.0759803922)";
        }

    }

    function showSuccessMsg(str) {
        str = str || '';
        toastr.options = {
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-top-center",
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000"
        };
        toastr["success"]("Data Inserted Successfully!", "Success: ");

    }

    function showErrorMsg(str) {
        str = str || '';
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-top-center",
            "preventDuplicates": true,
            "timeOut": 0,
            "extendedTimeOut": 0,
            "tapToDismiss": false
        }
        if (unselectedQuestions.length > 0) {
            const errorMessage = `Please answer: ${unselectedQuestions.join(', ')}`;
            toastr.error(errorMessage, "Error: ");
            unselectedQuestions = []

        }

        if ((err1 != "" || err2 != "" || err3 != "") && (err4 != "" || err5 != "" || err6 != "")) {

            toastr["error"](`<ul><li> Please enter: <br>${err1}${err2}${err3}<br></li><li> Please enter valid: <br>${err4}${err5}${err6}</li></ul>`, "Error :");
        }

        else if (err1 != "" || err2 != "" || err3 != "") {

            toastr["error"](`<ul><li> Please enter: <br>${err1}${err2}${err3}</li></ul>`, "Error :");
        }

        else if (err4 != "" || err5 != "" || err6 != "") {
            toastr["error"](`<ul><li> Please enter valid: <br>${err4}${err5}${err6}</li></ul>`, "Error :");
        }

    }

    const search = useLocation().search;
    const id = new URLSearchParams(search).get("id");

    //console.log(id);
    useEffect(() => {

        /* const query = new URLSearchParams(this.props.location.search);
         console.log(query);
         const token = query.get('id');
 console.log(token);//123*/
        getData();
    }, [])



    const postData = async () => {

        const usersName = JSON.stringify({
            'action': 'submitQues',
            'topic_id': id,
            'type_code': data2.type_code,
            'course_title': data2.course_title,
            'user_name': data2.user_name,
            'email': data2.email,
            'phone': data2.phone,
            'user_id': 0,
            'ques': data2.qus
        });

        const customConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        await axios.post('https://form.m2i.co.in/cpanel/wsApp/quiz.php', usersName, customConfig)
            .then((res) => {
                const jsonStartIndex = 0;//res.data.indexOf('{"status":true');

                const jsonVal = res.data.data;
                //console.log(jsonVal["status"]);

                if (jsonVal["status"] == "submitted") {
                    const jsonData = JSON.stringify(res.data.data);

                    try {

                        const encrypted = CryptoJS.AES.encrypt(jsonData, key, { iv: iv });
                        //const encrypted = CryptoJS.AES.encrypt(jsonData, 'secret key').toString();
                        setencryptedData(encrypted);
                        setResultData(data2)

                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('JSON data not found in the response');
                }

            }).
            catch(err => console.log(err))
    };

    const handleData = () => {
        if (name == "") {
            err1 = `Name<br>`
            document.getElementById("Name").style.border = "2px solid red"
        }
        if (phone == "") {
            err2 = `Phone Number <br>`
            document.getElementById("Phone").style.border = "2px solid red"
        }
        if (email == "") {
            err3 = `E-mail <br>`
            document.getElementById("email").style.border = "2px solid red"
        }
        if (!isValidName) {
            err4 = `Name<br>`
            document.getElementById("Name").style.border = "2px solid red"
        }
        if (!isValidPhone) {
            err5 = `Phone Number<br>`
            document.getElementById("Phone").style.border = "2px solid red"
        }
        if (!isValidEmail) {
            err6 = `E-mail <br>`
            document.getElementById("email").style.border = "2px solid red"
        }
    }


    const handlesubmit = (e) => {
        e.preventDefault();

    }

    const handlesubmitbtn = () => {


        handleErrors();
        handleData();
        postData();

        if (err1 == '' && err2 == '' && err3 == '' && err4 == '' && err5 == '' && err6 == '' && unselectedQuestions.length == 0) {
            if (encryptedData.length == 0) {
                document.getElementById("Quizform").style.display = "none"
                document.getElementById("resultChart1").style.display = "block"
            }
            else {
                document.getElementById("Quizform").style.display = "none"
                document.getElementById("resultChart").style.display = "block"
            }
        }
        else {
            showErrorMsg()
        }


    }

    const functionMap = {
        handlenumber: handlenumber,
        handlenegnumber: handlenegnumber,
        handleposnumber: handleposnumber
    };

    const valueMap = {
        text: text,
        number: number,
        negnumber: negnumber,
        posnumber: posnumber
    };
    const convertHtmlToText = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    let indexOfLastQuestion = currentPage * questionsPerPage;

    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = data.slice(indexOfFirstQuestion, indexOfLastQuestion);

    const totalPages = Math.ceil(data.length / questionsPerPage);

    const handleErrors = () => {
        const currentPageQuestions = data.slice(indexOfFirstQuestion, indexOfLastQuestion);
        currentPageQuestions.forEach((question, index) => {

            const questionNumber = question.qus_id;
            const answers = question.ans || [];
            if (answers.length > 0) {

                const allOptionsUnselected = answers.every(answer => answer.select_fl === "0");

                if (allOptionsUnselected) {
                    unselectedQuestions.push(`Q${questionNumber}`);
                }
            }
        });
    }
    if (indexOfLastQuestion > data.length) {
        indexOfLastQuestion = data.length;
    }

    const nextPage = () => {

        handleErrors();
        handleData();

        if (err1 == '' && err2 == '' && err3 == '' && err4 == '' && err5 == '' && err6 == '' && unselectedQuestions.length == 0) {
            setCurrentPage(currentPage + 1);
        } else {
            showErrorMsg()
        }
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const renderFormFields = () => {

        return currentQuestions.map((obj, index) => {

            return <div key={obj.qus_id}>

                <div className="d-flex flex-column" >

                    <label htmlFor={index} className='form-label'><strong>{obj.qus_no} - {convertHtmlToText(obj.qus)}</strong></label>
                    <div className='col-md-12'>
                        {obj.qus_type === 'TEXT' && (
                            <input type={obj.ans_type} className="form-control" id={obj.qus_id} value={valueMap[obj.value]} onChange={handleText({ index })} />
                        )}

                        {obj.qus_type === 'RADIO' && (
                            <>

                                {obj.ans.map((option, ansindex) => (

                                    <label key={option.ans_id} className='form-label d-flex gap-2 text-left'>
                                        <input type='radio' name={obj.qus_code} value={option.seq} onChange={handleRadio(obj.qus_id, { ansindex })} checked={selectedAnswers[obj.qus_id] === option.seq} />
                                        {option.ans}

                                    </label>
                                ))
                                }
                            </>
                        )}

                        {obj.qus_type === 'CHECKBOX' && (
                            <>
                                {obj.ans.map((option, ansindex) => (
                                    <label key={option.ans_id} className='form-label d-flex gap-2 text-left'>
                                        <input type={obj.ans_type} name={obj.qus_id} value={option.seq} onChange={handlecheck({ index }, { ansindex })} />
                                        {option.ans}
                                    </label>
                                ))
                                }
                            </>
                        )}

                        {obj.qus_type === 'NUMBER' && (
                            <input type={obj.ans_type} className="form-control" id={obj.qus_id} value={valueMap[obj.value]} onChange={functionMap[obj.onChange]} />
                        )}

                        {obj.qus_type === 'FILE' && (
                            <div className='d-flex flex-column flex-md-row gap-md-3 gap-2'>
                                <input type={obj.ans_type} accept="image/*" className="form-control" id={obj.qus_id} onChange={handleimage({ index })} />
                                <div >
                                    <button className='btn btn-secondary py-1' onClick={handleUpload}>Upload</button>
                                </div>
                            </div>
                        )}

                        {obj.qus_type === 'SELECT' && (
                            <>
                                <div key={index}>
                                    <select id={obj.qus_id} onChange={handleselect({ index })} className="form-select text-secondary col-3">
                                        <option value="" disabled selected>Select</option>
                                        {obj.ans.map((option, ansindex) => (
                                            <option key={ansindex} value={option.seq} >
                                                {option.ans}
                                            </option>))}
                                    </select>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        })
    }

    const handleReview = () => {
        document.getElementById("resultChart").style.display = "none"
        document.getElementById("review").style.display = "block"
    }

    return (
        <>
            <div className="card pb-1">
                <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme pb-1" id="navbar">
                    <div className="d-flex text-end justify-content-between align-items-center col-12 col-md-11 ps-md-5 pe-4 pe-md-0">
                        <div className="navbar-brand app-brand demo d-xl-flex py-0 px-3">
                            <div className="app-brand-link gap-2">
                                <span className="app-brand-logo demo">
                                    <img src={logo} alt="logo" style={{ width: "60px" }} />
                                </span>
                            </div>
                        </div>
                        <div className='text-secondary fw-semibold'>
                            Enabling Sustainable Enterprises
                        </div>
                    </div>
                </nav>
            </div>
            <div class="row me-0">
                <div class="col-md-3">
                </div>
                <div class="col-md-6 pe-0">
                    <div className=" d-flex flex-column justify-content-center align-items-center p-md-4 ps-md-3 p-3 pt-md-3 pt-2 mt-md-5 mt-3 " >

                        <form className="card mb-4 col-12 col-md-9 text-secondary" onSubmit={handlesubmit} id="Quizform" style={{ display: "block" }}>
                            <div className="card-body d-flex flex-column gap-2 p-5 pb-3">
                                <input type="hidden" className="form-control" id="topicID" value={topic} onChange={(e) => setTopic(e.target.value)} placeholder="" />
                                <div style={{ display: currentPage == 1 ? 'block' : 'none' }} >

                                    <h5 className='text-center' style={{ display: currentPage == 1 ? 'block' : 'none' }}>{data2.course_title}</h5>
                                    <p>{data2.topic_text}</p>
                                    <div className='d-flex flex-column gap-3 gap-md-4 col-md-11 justify-content-center mt-2 mb-4' >
                                        <div className='d-flex flex-column col-md-12'>
                                            <label htmlFor="Name" className='form-label'><strong>Name:</strong></label>
                                            <input type="text" className="form-control" id="Name" value={name} onChange={handleName} />
                                        </div>
                                        <div className='d-flex flex-column col-md-12'>
                                            <label htmlFor="Phone" className='form-label'><strong>Phone:</strong></label>
                                            <input type="number" className="form-control" id="Phone" value={phone} onChange={handlephone} />
                                        </div>
                                        <div className='d-flex flex-column  col-md-12'>
                                            <label htmlFor="email" className='form-label'><strong>E-mail:</strong></label>
                                            <input type="text" className="form-control" id="email" value={email} onChange={handleemail} />
                                        </div>
                                    </div>
                                </div>

                                {renderFormFields()}
                            </div>


                            <div className="d-flex flex-row justify-content-center gap-md-3 gap-2 py-4 ">
                                <button className='btn-secondary rounded py-md-2 waves-effect px-md-4 px-2 py-1' id='backButton' onClick={prevPage} disabled={currentPage == 1} style={{ display: currentPage != 1 ? 'block' : 'none' }}>PREVIOUS</button>
                                <button className='btn-primary rounded waves-effect py-md-2 px-md-4 px-2 py-1' id='submitButton' onClick={handlesubmitbtn} style={{ display: currentPage === totalPages ? 'block' : 'none' }}>SUBMIT</button>
                                <button className='btn-primary waves-effect rounded py-md-2 px-md-4 px-2 py-1' id='nextButton' onClick={nextPage} disabled={indexOfLastQuestion >= data.length} style={{ display: currentPage != totalPages ? 'block' : 'none' }}>NEXT</button>

                            </div>

                            <div class="row me-0">
                                <div className='ps-md-5 col-md-8 col-12 mb-3 text-center text-md-start'>
                                    {currentPage !== totalPages && (
                                        <div className=" text-black-50 small ">
                                            {data.length == 0 ? null :
                                                totalPages > currentPage ? `Q ${data[indexOfFirstQuestion + questionsPerPage].qus_no} to Q ${data[Math.min(indexOfLastQuestion + questionsPerPage - 1, data.length - 1)]?.qus_no} on Page ${currentPage + 1}` : null}
                                        </div>
                                    )}
                                </div>
                                <div class="col-md-4 pe-md-5 col-12 border-bottom pb-4 text-center text-md-end">
                                    {totalPages > 1 ?
                                        <div className='small  text-black-50 '>
                                            Page {currentPage} of {totalPages}
                                        </div> :
                                        null}
                                </div>

                            </div>



                        </form>

                        {encryptedData == "" ?
                            <div id="resultChart1" className='vh-100' style={{ display: "none" }}> Loading...</div> :
                            <div id="resultChart" style={{ display: "none" }} className="card mb-4 col-12 col-md-9 text-secondary">
                                <QuizResultChart encryptedData={encryptedData} />
                                <div className='d-flex flex-column align-items-center mb-5 '>
                                    <button className='btn-primary waves-effect rounded py-md-2 px-md-4 px-2 py-1' onClick={handleReview}>REVIEW RESULT</button>
                                </div>
                            </div>}

                        <div id="review" style={{ display: "none" }} className="card mb-4 col-12 col-md-9 text-secondary">
                            <Review data1={resultData} />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Questions;