
function Footer() {
  return (
    <footer className="content-footer footer bg-footer-theme ">
      <div className="fw-light">
        <div className="footer-container d-flex align-items-center justify-content-between py-md-3 flex-md-row ps-md-5 ps-3">
          <div className="mb-2 mb-md-0 ms-md-4 ">
            <small>
              © 2024, made with ❤️ by Exact IT Solutions Pvt. Ltd.
            </small>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;