import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loginpage from './components/Loginpage';
import Category from './components/Category';
import Subcategory from './components/Subcategory';
import Categorylist from './components/Categorylist';
import Subcategorylist from './components/Subcategorylist';
import QuestionBank from './components/QuestionBank';
import Questionnaire from './components/Questionnaire';
import Questions from './components/Questions';
import MoreQuestions from './components/MoreQuestions';
import ImageUploadForm from './components/ImageUploadForm';
import QuestionList from './components/Questionlist';



function App() {

  

  return (
    <>
    <div class="bg-light">
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Questions />}/>
          <Route path="/quiz" element={<Questions/>} />
     </Routes>
    </BrowserRouter>
    </div>
    </>
    );
}

export default App;
